import React, {
    useState, useEffect, FC, PropsWithChildren, KeyboardEvent, MouseEvent, ComponentProps,
    useMemo
} from "react";
import Head from "next/head";
import Script from "next/script";
import getConfig from "next/config";
import NodeCache from "node-cache";
import { GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import { UseComboboxStateChange } from "downshift"; // eslint-disable-line import/no-extraneous-dependencies

import {
    ComposableHeaderDropdownProviderProps,
    Header,
    IndexedObject,
    PageLayout,
    Search,
    useSessionStorage,
} from "@themuse/design-system";
import {
    initSnowplowTracker,
    trackSnowplowImpressions,
    triggerSnowplowPageViewEvent,
    trackSnowplowClicks,
    trackSnowplowModalActions
} from "@themuse/snowplow-js-client";

import { UserActionTypes, PageMetaDataActionTypes } from "interfaces/context/appContextInterfaces";
import { pushDataLayerEvent } from "utils/tracking";
import { isBrowser } from "utils/browser";
import { getActiveFeatureFlags } from "utils/featureFlags";
import { formatLog } from "utils/logging";
import {
     getRecentArticles,
    CompanySuggestion,
    fetchApiCarmenData,
    fetchCompanySuggestions,
    fetchUser,
    getFeaturedHomepageCompanies,
    newsletterSubscribeRequest,
} from "utils/api";

import { AppProvider, useAppState, useAppDispatch } from "hooks/AppContext";
import { FeatureFlagsProvider } from "hooks/FeatureFlagsContext";
import { AppPageLayout } from "components/AppPageLayout";
import { getFetchError } from "utils/errors";
import { ContentWell } from "Homepage/ContentWell";
import { IFeaturedCompanies } from "interfaces/featuredCompanies";

import styles from "styles/Home.module.scss";
import { IArticleTile } from "Homepage/RecentArticles";

interface InitialAppParams {
    featuredCompanies: IFeaturedCompanies;
    recentArticles: IArticleTile[],
    path: string;
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
    let initialAppParams: InitialAppParams = {
        featuredCompanies: null,
        recentArticles: null,
        path: "",
    };

    initialAppParams = {
        ...initialAppParams,
        ...await getInitialAppParams(context)
    };

    return {
        notFound: false,
        props: {
            ...initialAppParams,
            featureFlags: {
                ...getActiveFeatureFlags(context),
            }
        }
    };
};

// Cash all the things for one hour.
const apiCache = new NodeCache({ stdTTL: 3600, checkperiod: 300 });

const getInitialAppParams = async (context: GetServerSidePropsContext): Promise<InitialAppParams> => { // eslint-disable-line
    let featuredCompanies: IFeaturedCompanies = apiCache.get("featuredCompanies");
    let recentArticles: IArticleTile[] = apiCache.get("recentArticles");

    if (featuredCompanies === undefined) {
        featuredCompanies = await getFeaturedHomepageCompanies();
        apiCache.set("featuredCompanies", featuredCompanies);
        console.info("Cache refreshed, featuredCompanies retrieved from API.");
    }

    if (recentArticles === undefined) {
        recentArticles = await getRecentArticles();
        apiCache.set("recentArticles", recentArticles);
        console.info("Cache refreshed, recentArticles retrieved from API.");
    }

    return {
        recentArticles,
        featuredCompanies,
        path: context.resolvedUrl
    };
};

const setUserCarmenData = async (setUserLocation: any) => {
    try {
        const carmenResponse = await fetchApiCarmenData();
        if (carmenResponse) {
            setUserLocation(JSON.stringify(carmenResponse));
        }
    } catch (error) {
        const msg = getFetchError("Carmen Location", "Front-End");
        console.error(msg, JSON.stringify(formatLog({ error })));
    }
};

const setUser = async (userLocation, dispatch) => {
    try {
        const apiUser = await fetchUser();
        const userData = userLocation ? {
            location: JSON.parse(userLocation)
        } : {};
        dispatch({
            type: UserActionTypes.updateUser,
            payload: {
                ...apiUser,
                location: userData,
                isInitialized: true,
            }
        });
    } catch (error) {
        dispatch({
            type: UserActionTypes.updateUser,
            logged_in: false,
            view_bookings: false,
            location: null,
            isInitialized: true,
        });
        const msg = getFetchError("User", "Front-End");
        console.error(msg, JSON.stringify(formatLog({ error })));
    }
};

const UserInit: FC<PropsWithChildren<{}>> = ({ children }) => {
    const { user } = useAppState();
    const [userLocation, setUserLocation] = useSessionStorage("user_location");
    const appDispatch = useAppDispatch();

    useEffect(() => {
        setUser(userLocation, appDispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLocation]);

    useEffect(() => {
        setUserCarmenData(setUserLocation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        appDispatch({
            type: UserActionTypes.updateUser,
            payload: {
                ...user,
                location: userLocation ? JSON.parse(userLocation) : user?.location
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLocation]);

    return <>{ children }</>;
};

const PageMetaInit: FC<PropsWithChildren<{}>> = ({ children }) => {
    const { pageMetaData } = useAppState();
    const appDispatch = useAppDispatch();

    useEffect(() => {
        const { href, search } = window.location;
        const { referrer } = document;
        appDispatch({
            type: PageMetaDataActionTypes.updatePageMetaData,
            payload: {
                ...pageMetaData,
                ...{
                    href,
                    search,
                    referrer
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{ children }</>;
};

const SnowPlowInit = () => {
    const { publicRuntimeConfig: { snowplowCollector } } = getConfig();
    const { user } = useAppState();
    const [pageViewed, setPageViewed] = useState<boolean>(false);

    const pageData = useMemo(() => ({
        sp_page_is_external: false,
        sp_page_section: "homepage",
        sp_page_sponsor_id: null,
        sp_page_tab: null,
        sp_page_type: "other",
        sp_user_logged_in: user?.logged_in ?? false,
        sp_user_id: user?.id ?? 0,
        sp_experiment_experiment_id: "",
        sp_experiment_variant_id: ""
    }), [user]);

    useEffect(() => {
        if (!pageViewed) {
            // eslint-disable-next-line no-underscore-dangle
            const experimentData = {};

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                ...pageData,
                ...experimentData
            });
            initSnowplowTracker(snowplowCollector);
            trackSnowplowImpressions();
            trackSnowplowClicks();
            trackSnowplowModalActions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (user.isInitialized && !pageViewed) {
            triggerSnowplowPageViewEvent();
            setPageViewed(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return null;
};

export const formatCompanySuggestions = (companySuggestions: CompanySuggestion[]) => companySuggestions.slice(0, 4).map((companyResult: CompanySuggestion) => {
    const { text, value } = companyResult;
    const { short_name } = value; // eslint-disable-line camelcase
    return {
        label: `${text}`,
        value: `/profiles/${short_name}` // eslint-disable-line camelcase
    };
});

export const updateInput = async (
    changes: UseComboboxStateChange<IndexedObject>,
    setItems: (items: IndexedObject[]) => void,
    setInputValue: (inputValue: string) => void,
    fetchData:(inputValue: string) => Promise<CompanySuggestion[]>
) => {
    const { inputValue } = changes;
    const jobSearchItem = {
        label: `${inputValue}`,
        value: `/search/keyword/${inputValue}/`,
    };
    setInputValue(inputValue);
    if (inputValue) {
        setItems([jobSearchItem]);
        const companyResults = await fetchData(inputValue);
        const companyList = companyResults?.length ? formatCompanySuggestions(companyResults) : [];
        setItems([jobSearchItem].concat(companyList));
    } else {
        setItems([]);
    }
};

const HomePage = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
    const {
        path, featureFlags, featuredCompanies, recentArticles
    } = props;
    const pageMetaData = {
        pathName: path
    };
    const [dropdownItems, setDropdownItems] = useState([] as IndexedObject[]);
    const [inputValue, setInputValue] = useState("");

    const searchQuerySubmittedEvent = (selectedItemText: string) => {
        const searchTerm = selectedItemText || inputValue;
        const eventProps = {
            search_term: searchTerm || ""
        };
        pushDataLayerEvent("searchQuerySubmitted", eventProps);
    };

    const onKeyboardSelectItem = (_: KeyboardEvent<HTMLInputElement>, selectedItem: IndexedObject) => {
        if (!isBrowser()) return "";

        const searchPath = inputValue
            ? `/search/keyword/${inputValue}`
            : "/search";
        const selectPath = selectedItem?.value
            ? selectedItem?.value
            : searchPath;

        searchQuerySubmittedEvent(selectedItem?.label);

        return window.open(selectPath, "_blank").focus();
    };

    const onMouseSelectItem = (_: MouseEvent<HTMLInputElement>, selectedItem: IndexedObject) => {
        searchQuerySubmittedEvent(selectedItem.label);
    };

    const dropdownProps: ComposableHeaderDropdownProviderProps = {
        IconComponent: <Search />,
        debounceWait: 10,
        dropdownList: dropdownItems,
        inputId: "search-input",
        onInputChange: (changes: UseComboboxStateChange<IndexedObject>) => updateInput(
            changes,
            setDropdownItems,
            setInputValue,
            fetchCompanySuggestions
        ),
        noResultsMessage: " ",
        onKeyboardSelectItem,
        onMouseSelectItem
    };

    // A list of allowed ad types om the homepage.
    const allowList = [
        "adhesion-mobile",
        "adhesion-tablet",
        "adhesion-desktop"
    ];

    const pageLayoutProps: ComponentProps<typeof PageLayout> & PropsWithChildren<ComponentProps<typeof Header>> = {
        activePath: "/",
        headerClassName: styles.pageHeader,
        pageName: styles.pageWrapper,
        mainClassName: styles.main,
        enableCompanySearch: false,
        companySearchDropdownProps: dropdownProps,
        showSubMenus: true,
        handleModalFormSubmit: newsletterSubscribeRequest
    };

    return (
        <>
            <Head>
                <title>The Muse - Career advice and better job search</title>
                <link rel="canonical" href="https://www.themuse.com" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Find jobs at the best companies hiring near you and get free career advice." />
                <meta name="description" content="Find jobs at the best companies hiring near you and get free career advice." />
                <meta property="og:title" content="The Muse - Career advice and better job search" />
                <meta name="robots" content="index,follow" key="robots" />
            </Head>
            <FeatureFlagsProvider featureFlags={featureFlags}>
                <Script
                    id="Organization-JSON"
                    type="application/ld+json"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            name: "The Muse",
                            url: "https://www.themuse.com/",
                            logo: "https://www.themuse.com/static/images/muse-amp-logo.jpg?v=212881e6fc388b7a61e11bb9d8ab8d0a879e4dc1a4f5ac328c41df8b0f6ee5ab",
                            sameAs: [
                                "https://www.facebook.com/thedailymuse",
                                "https://twitter.com/TheMuse",
                                "https://www.instagram.com/themuse/",
                                "https://www.youtube.com/channel/UCk4bbQAZD26f_XdGyb4wwhg",
                                "https://www.linkedin.com/company/the-daily-muse/",
                                "https://en.wikipedia.org/wiki/The_Muse_(website)",
                                "https://www.pinterest.com/thedailymuse/_created/"
                            ]
                        })
                    }}
                />
                <Script
                    id="WebSite-JSON"
                    type="application/ld+json"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify({
                            "@context": "https://schema.org/",
                            "@type": "WebSite",
                            name: "The Muse",
                            url: "https://www.themuse.com/",
                            potentialAction: {
                                "@type": "SearchAction",
                                target: "https://www.themuse.com/search?keyword={search_term_string}",
                                "query-input": "required name=search_term_string"
                            }
                        })
                    }}
                />
                <AppProvider {...{ pageMetaData }}>
                    <UserInit>
                        <PageMetaInit>
                            <SnowPlowInit />
                            <AppPageLayout {...pageLayoutProps}>
                                <ContentWell {...{ featuredCompanies, recentArticles }} />
                            </AppPageLayout>
                        </PageMetaInit>
                    </UserInit>
                </AppProvider>
            </FeatureFlagsProvider>
        </>
    );
};

export default HomePage;
